import React, { useEffect, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import nanoid from 'nanoid';
import { useForm } from 'react-hook-form';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import { useSkill } from './hooks';

import { useFromQuery } from '~/hooks/useFromQuery';
import { useLanguageState } from '~/hooks/useLanguageState';
import { deleteSkills, updateSkill } from '~/services/skills';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';

import { parseJobProfiles } from '../SkillEdit/utils';
import { SkillForm } from '../SkillForm';
import { useSkillCategories } from '../SkillForm/hooks';
import { removeEmptyValues } from '../SkillForm/utils';
import { resolver } from '../SkillForm/validations';

import type { IGeneralForm } from '../SkillForm/types';
import type { IJobProfile, ISkillCategory } from '@learned/types';

function SkillEdit() {
  const { i18n } = useLingui();
  const { goBack } = useFromQuery({ includeHash: true });
  const { addToast } = useToasts();
  const { skillCategories } = useSkillCategories();
  const { skill } = useSkill();

  const [skillCategory, setSkillCategory] = useState<ISkillCategory>();
  const [currentStatus, setCurrentStatus] = useState<boolean>();
  const languageState = useLanguageState();
  const generalFormMethods = useForm<IGeneralForm>({
    mode: 'all',
    resolver,
    defaultValues: {
      name: languageState.companyLanguages.map(({ locale }) => ({ locale, value: '' })),
      description: languageState.companyLanguages.map(({ locale }) => ({ locale, value: '' })),
      focusAreas: new Array(5).fill(0).map((_, index) => ({
        values: [],
        level: index,
      })),
      jobProfiles: new Array(5)
        .fill(0)
        .reduce((acc: Record<number, IJobProfile[]>, _, index) => ({ ...acc, [index]: [] }), {}),
    },
  });
  const { watch } = generalFormMethods;

  const skillName =
    watch('name').find(({ locale }) => locale === languageState.companyPrimaryLanguage.locale)
      ?.value || watch('name').find(({ value }) => value)?.value;
  const title = i18n._(t`Update a skill: ${skillName}`);

  useEffect(() => {
    if (!skill || !skillCategory) {
      return;
    }

    setCurrentStatus(skill.published);

    const focusAreas: IGeneralForm['focusAreas'] | undefined = skillCategory?.skillLevels.map(
      (_, index) => ({
        level: index,
        values: [],
      }),
    );
    skill.focusAreas.forEach((focusAreaFromSkill) => {
      const focusArea = focusAreas?.find((area) => area.level === focusAreaFromSkill.level);
      focusAreaFromSkill.values.forEach((value) => {
        focusArea?.values.push({ ...value, _id: value.id || nanoid() });
      });
    });

    generalFormMethods.reset({
      name: languageState.companyLanguages.map(({ locale }) => ({
        locale,
        value: skill.name[locale] ?? '',
      })),
      description: languageState.companyLanguages.map(({ locale }) => ({
        locale,
        value: skill.description[locale] ?? '',
      })),
      focusAreas,
      jobProfiles: parseJobProfiles(skill, skillCategory),
      skillCategory: skill.skillCategory,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillCategory, skill]);

  useEffect(() => {
    const preSelectedSkillCategory = skillCategories.find(
      (item) => item.id === skill?.skillCategory,
    );
    setSkillCategory(preSelectedSkillCategory);
  }, [skill, skillCategories]);

  const onSubmit = async (data: IGeneralForm & { published: boolean }) => {
    const predicate = ({ value, locale }: { value: string; locale: string }) =>
      value !== '' || locale === '_id';

    const transformedData = {
      ...data,
      id: skill?.id,
      name: convertLanguageValue(data.name.filter(predicate)),
      description: convertLanguageValue(data.description.filter(predicate)),
      jobProfiles: Object.entries(data.jobProfiles).reduce((acc, [key, value]) => {
        return { ...acc, [key]: value.map((jp) => jp.id) };
      }, {}),
      focusAreas: data.focusAreas
        .map(({ values, level }) => ({
          values: values.map(({ _id, name }) => ({ id: _id, name: removeEmptyValues(name) })),
          level,
        }))
        .slice(0, skillCategory?.skillLevels?.length ?? 5),
    };

    const result = await updateSkill(transformedData, transformedData.jobProfiles);

    if (result.code === 200 && result.data.skill.published === true) {
      addToast({
        title: i18n._(t`Success`),
        subtitle: i18n._(t`Skill published`),
        type: TOAST_TYPES.SUCCESS,
      });
      goBack();
    } else if (result.code === 200 && result.data.skill.published === false) {
      addToast({
        title: i18n._(t`Success`),
        subtitle: i18n._(t`Skill saved as draft`),
        type: TOAST_TYPES.SUCCESS,
      });
      goBack();
    }
  };

  return (
    <SkillForm
      title={title}
      formMethods={generalFormMethods}
      languageState={languageState}
      onSubmit={onSubmit}
      goBack={goBack}
      skillCategory={skillCategory}
      skillCategories={skillCategories}
      setSkillCategory={setSkillCategory}
      currentStatus={currentStatus}
      onDelete={async () => {
        if (
          skill?.id &&
          (await confirm({
            type: CONFIRMATION_MODAL_TYPE.DELETE,
            title: i18n._(t`Delete?`),
            description: i18n._(
              t`Are you sure you want to delete the skill? This cannot be undone.`,
            ),
          }))
        ) {
          deleteSkills([skill?.id]);
        }
        goBack();
      }}
    />
  );
}

export { SkillEdit };
