import React, { memo } from 'react';

import styled from 'styled-components';

import { COLORS } from '~/styles';
import sanitizeHtml from '~/utils/sanitize';

const Text = styled.div<{ fontColor?: string; color?: string; fontWeight?: string }>`
  p {
    margin: 0;
    font-size: 14px;
    font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
  }
  li {
    padding: 0;
    font-size: 14px;
    font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
  }
  ul {
    padding: 12px 0 0 0;
    list-style-position: inside;
    margin: 0 0 0 12px;
  }
  ol {
    padding: 12px 0 0 0;
    list-style-position: inside;
    margin: 0 0 0 12px;

    + ul {
      padding-left: 24px;
    }
  }

  highlight {
    background-color: ${COLORS.COMPANY_LIGHT};
  }

  color: ${COLORS.TEXT_BLACK};
  ${({ fontColor }) => fontColor && `color: ${fontColor}`};
  ${({ color }) => color && `color: ${color}`}
`;

type RickTextViewProps = {
  color?: string;
  fontColor?: string;
  fontWeight?: string;
  isHtmlWithoutStyles?: boolean;
  className?: string;
  html?: string;
  highlight?: string;
};

const highlightText = (text: string, highlight: string) => {
  if (!highlight?.trim()) {
    return text;
  }

  const escapeRegExp = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const escapedHighlight = escapeRegExp(highlight);
  const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));

  return parts
    .map((part) =>
      part.toLowerCase() === highlight.toLowerCase() ? `<highlight>${part}</highlight>` : part,
    )
    .join('');
};

const RickTextView = ({
  html,
  isHtmlWithoutStyles,
  className,
  highlight = '',
  ...props
}: RickTextViewProps) => {
  if (!html) {
    return <></>;
  }

  const highlightHTML = highlightText(html, highlight);

  return (
    <Text
      className={className}
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(highlightHTML, isHtmlWithoutStyles),
      }}
      {...props}
    />
  );
};
export default memo(RickTextView);
