import { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  GOAL_STATUSES_NEW,
  ProductName,
  USER_PREVIEW_FIELDS,
  USER_REVIEW_STATUS,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import { ICONS } from '~/components/Icon';

import {
  CareerPlansSection,
  type IUserReviewsPreview,
  type IUserReviewsSectionDashboard,
  type ICareerPlansPreview,
  type ICareerPlansSectionDashboard,
  type IGoalsPreview,
  type IGoalsSectionDashboard,
} from './types';

import useBoolState from '~/hooks/useBoolState';
import { getUsers } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getUserPreview } from '~/services/users';
import { COLORS } from '~/styles';
import { isProductEnabled } from '~/utils/company';
import { isNotFalse } from '~/utils/typePredicates';

import type { IUser, IUserReview } from '@learned/types';

const useUserPreview = ({
  userId,
  userReviewId,
}: {
  userId: IUser['id'];
  userReviewId: IUserReview['id'];
}) => {
  const { i18n } = useLingui();
  const [goals, setGoals] = useState<IGoalsSectionDashboard[]>([]);
  const [careerPlans, setCareerPlans] = useState<ICareerPlansSectionDashboard[]>([]);
  const [userReviews, setUserReviews] = useState<IUserReviewsSectionDashboard[]>([]);
  const [user, setUser] = useState<IUser>();
  const company = useSelector(getCurrentCompany);
  const isCareerProductEnabled = isProductEnabled(company, ProductName.CAREER);
  const isJobMatrixProductEnabled = isProductEnabled(company, ProductName.JOB_MATRIX);
  const allUsers = useSelector(getUsers);
  const isLoading = useBoolState(true);

  useEffect(() => {
    // get user info
    const user = Object.values(allUsers as Record<string, IUser>).find(
      (item) => item.id === userId,
    );
    setUser(user);
  }, [allUsers, userId]);

  const fetchUserPreview = async (userId: string) => {
    const res = await getUserPreview({
      userId,
      fields: [
        USER_PREVIEW_FIELDS.GOALS,
        USER_PREVIEW_FIELDS.CAREER,
        USER_PREVIEW_FIELDS.USER_REVIEWS,
      ],
      userReviewId,
    });

    setGoals(prepareGoals(res.data?.[API_RETURN_FIELDS.GOALS]));
    setCareerPlans(prepareCareerPlans(res.data?.[API_RETURN_FIELDS.CAREER_PLANS]));
    setUserReviews(prepareUserReviews(res.data?.[API_RETURN_FIELDS.USER_REVIEWS]));
    isLoading.off();
  };

  useEffect(() => {
    if (userId) {
      fetchUserPreview(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const prepareGoals = (goals: IGoalsPreview): IGoalsSectionDashboard[] => {
    return [
      {
        key: GOAL_STATUSES_NEW.TODO,
        title: i18n._(t`To do`),
        icon: ICONS.TODO,
        color: COLORS.ACTIVE,
        items: goals.todo,
      },
      {
        key: GOAL_STATUSES_NEW.IN_PROGRESS,
        title: i18n._(t`In progress`),
        icon: ICONS.IN_PROGRESS,
        color: COLORS.STATUS_IN_PROGRESS,
        items: goals.in_progress,
      },
      {
        key: GOAL_STATUSES_NEW.COMPLETED,
        title: i18n._(t`Completed`),
        icon: ICONS.CHECKMARK,
        color: COLORS.CONFIRMATION_MODAL_SUCCESS,
        items: goals.completed,
      },
      {
        key: GOAL_STATUSES_NEW.PROBLEM,
        title: i18n._(t`Problem`),
        icon: ICONS.WARNING,
        color: COLORS.CONFIRMATION_MODAL_DELETE,
        items: goals.problem,
      },
      {
        key: GOAL_STATUSES_NEW.ARCHIVED,
        title: i18n._(t`Archived`),
        icon: ICONS.ARCHIVE_2,
        color: COLORS.LABEL_GRAY,
        items: goals.archived,
        isCollapsible: true,
      },
    ];
  };

  const prepareCareerPlans = (careerPlans: ICareerPlansPreview): ICareerPlansSectionDashboard[] => {
    return [
      {
        key: CareerPlansSection.ACTIVE,
        title: i18n._(t`Active`),
        placeholder: i18n._(t`No active job yet...`),
        icon: ICONS.WORK,
        color: COLORS.ACTIVE,
        items: careerPlans.active,
      },
      isCareerProductEnabled && {
        key: CareerPlansSection.AMBITION,
        title: i18n._(t`Ambition`),
        placeholder: i18n._(t`No saved ambition yet...`),
        icon: ICONS.FAVORITE,
        color: COLORS.STATUS_CALIBRATE,
        items: careerPlans.ambition,
      },
      {
        key: CareerPlansSection.PREVIOUS,
        title: i18n._(t`Previous`),
        placeholder: i18n._(t`No previous job yet...`),
        icon: ICONS.WORK_HISTORY,
        color: COLORS.PREVIOUS,
        items: careerPlans.previous,
        isCollapsible: true,
      },
    ].filter(isNotFalse);
  };

  const prepareUserReviews = (userReviews: IUserReviewsPreview): IUserReviewsSectionDashboard[] => {
    return [
      {
        key: USER_REVIEW_STATUS.COMPLETED,
        title: i18n._(t`Completed`),
        icon: ICONS.CHECKMARK,
        color: COLORS.CONFIRMATION_MODAL_SUCCESS,
        items: userReviews.completed,
      },
      {
        key: USER_REVIEW_STATUS.SIGNING,
        title: i18n._(t`Signing`),
        icon: ICONS.SIGNATURES,
        color: COLORS.STATUS_IN_PROGRESS,
        items: userReviews.signing,
      },
      {
        key: USER_REVIEW_STATUS.ACTIVE,
        title: i18n._(t`Active`),
        icon: ICONS.TODO,
        color: COLORS.TODO_BLUE,
        items: userReviews.active,
      },
      {
        key: USER_REVIEW_STATUS.PUBLISHED,
        title: i18n._(t`Upcoming`),
        icon: ICONS.UPCOMING,
        color: COLORS.STATUS_UPCOMING,
        items: userReviews.published,
      },
      {
        key: USER_REVIEW_STATUS.ARCHIVED,
        title: i18n._(t`Archived`),
        icon: ICONS.ARCHIVE_2,
        color: COLORS.LABEL_GRAY,
        items: userReviews.archived,
        isCollapsible: true,
      },
    ];
  };

  return {
    user,
    items: { goals, careerPlans, userReviews },
    refetchData: fetchUserPreview,
    permissions: { isJobMatrixProductEnabled },
    isLoading,
  };
};

export { useUserPreview };
